import { graphql, useStaticQuery } from 'gatsby'

/**
 * Статичные опции темы
 */
export default () => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					title
					description
					author
					siteUrl
					siteApiUrl
					partnerUrl
					partnerApiUrl
					databasesActual
					schema {
						name
						alternateName
						legalName
						foundingDate
						addressCountry
						addressLocality
						streetAddress
						postalCode
						telephones
						emails
						founders
						author {
							name
							url
						}
						#						socials {
						#							name
						#							url
						#						}
					}
				}
			}
		}
	`)
	return data.site.siteMetadata
}
