import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'isomorphic-fetch'

export const createClient = uri => {
	const httpLink = createHttpLink({
		uri: uri + '/graphql',
	})

	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		const token = false // will use with preview later.

		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		}
	})

	return new ApolloClient({
		link: authLink.concat(httpLink),
		credentials: 'include',
		cache: new InMemoryCache(),
		fetch,
	})
}
