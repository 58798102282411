/**
 * Работа с LocalStorage
 */
class LocalStorage {
	constructor(key) {
		this.key = key
	}

	_getStore() {
		return JSON.parse(localStorage.getItem(this.key) || '{}')
	}

	_isUnSupported() {
		return typeof localStorage === 'undefined'
	}

	get(key) {
		if (this._isUnSupported()) return null
		const store = this._getStore()
		return store[key]
	}

	set(key, value) {
		if (this._isUnSupported()) return
		const store = this._getStore()
		localStorage.setItem(this.key, JSON.stringify({ ...store, [key]: value }))
	}

	delete(key) {
		if (this._isUnSupported()) return null
		const store = this._getStore()
		delete store[key]
		localStorage.setItem(this.key, JSON.stringify(store))
	}
}

export default LocalStorage
