import React, { useContext, useEffect } from 'react'
import OrdersContext from 'contexts/orders'
import LocalStorage from 'utils/LocalStorage'
import NotificationsContext from 'contexts/notifications'
import useSiteMetadata from 'hooks/useSiteMetadata'
import LinkDuplicate from 'components/LinkDuplicate'
import declension from 'utils/declension'

const storage = new LocalStorage('orders')

const WithOrdersContext = props => {
	const notificationsContext = useContext(NotificationsContext)
	const { siteApiUrl } = useSiteMetadata()

	if (!storage.get('data')) storage.set('data', [])

	const context = {}

	// Текущая страница - это страница заказа
	context.isOrderPage = () => window.location.pathname.indexOf('/order/') === 0

	// Номер заказа из URL-адреса
	context.getClientOrderIdFromURL = () => {
		const matches = window.location.pathname.match(/^\/order\/([^/]+)/)
		return matches ? matches[1] : null
	}

	// Добавление ID заказа
	context.add = async id => {
		let data = storage.get('data')
		if (data.find(v => v.id === id)) return
		data.push({ id, date: Date.now() })
		storage.set('data', data)
	}

	// Удаление ID заказа
	context.remove = async id => {
		let data = storage.get('data')
		data = data.filter(v => v.id !== id)
		storage.set('data', data)
	}

	// Получение данных с сервера об оплаченных заказах
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		const interval = setInterval(() => {
			// Удаление старых заказов созданных более 20 минут назад
			const oldOrders = storage.get('data').filter(v => v.date + 1000 * 60 * 20 < Date.now())
			oldOrders.forEach(order => context.remove(order.id))

			// Заказ уже открыт
			if (context.isOrderPage()) return

			let data = storage.get('data')
			if (!data.length) return

			fetch(`${siteApiUrl}/store/orders/check`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json;charset=utf-8' },
				body: JSON.stringify({ ids: data.map(v => v.id) }),
			})
				.then(async response => {
					// Все проверки на сервере прошли успешно => парсим ответ
					if (response.ok) return await response.json()
				})
				.then(res => {
					if (!res) return

					// Удаление интервала после показа уведомления
					if (res.orders && res.orders.length) clearInterval(interval)

					res.orders.forEach(order => {
						const id = notificationsContext.add({
							title: `Спасибо вам за покупку!`,
							text: (
								<>
									Скачайте {declension(order.databasesLength, 'базы', 'базу', 'базы')}{' '}
									<LinkDuplicate
										to={`/order/${order.clientOrderId}`}
										title={`Открыть заказ`}
										onClick={() => {
											// Удаление подсказки, но не номера заказа из LocalStorage
											notificationsContext.remove(id)
										}}
									>
										на этой странице
									</LinkDuplicate>
								</>
							),
						})
					})
				})
		}, 2500)
	}, [])

	return <OrdersContext.Provider value={context}>{props.children}</OrdersContext.Provider>
}

export default WithOrdersContext
