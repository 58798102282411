import React, { useState } from 'react'
import NotificationsContext from 'contexts/notifications'

const WithNotificationsContext = props => {
	const [notifications, setNotifications] = useState([])

	const context = { notifications }

	context.add = data => {
		data.id = Date.now()
		setNotifications(prev => [...prev, data])
		return data.id
	}

	context.remove = id => {
		setNotifications(prev => prev.filter(v => v.id !== id))
	}

	return <NotificationsContext.Provider value={context}>{props.children}</NotificationsContext.Provider>
}

export default WithNotificationsContext
