import React from 'react'
import { Location } from '@reach/router'

/**
 * pathname: /db-builder.html
 * search: ?test
 * hash: #123
 * @param Component
 * @returns {function(*)}
 * @constructor
 */
const WithLocation = Component => {
	const WithLocationComponent = props => {
		return (
			<Location>
				{({ location }) => {
					return <Component location={location} {...props} />
				}}
			</Location>
		)
	}
	return WithLocationComponent
}

export default WithLocation
