module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Парсинг сайтов «Парсик»","short_name":"Парсинг сайтов «Парсик»","start_url":"/","background_color":"#0050B3","theme_color":"#0050B3","icon":"src/images/icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"334856b882ea7a2d3a8dff95515042a3"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"4641e8fb-2e26-4e6b-81e4-c3a8ef1abe40","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../../gatsby-theme-blog/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.parsic.ru","wordpressUrl":"https://wp.parsic.ru"},
    }]
