/**
 * Склонение слов
 * @param num
 * @param many
 * @param one
 * @param two
 * @returns {*}
 */
const declension = (num, many, one, two) => {
	let last = Math.abs(num) % 100
	let format = many

	if (last <= 10 || last >= 20) {
		last = last % 10

		if (last === 1) format = one
		else if (last >= 2 && last <= 4) format = two
	}

	return format
}

export default declension
