import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { createClient } from './src/apollo/client'
import { ThemeContext } from './context'
import defaultOptions from './src/utils/defaultOptions'
import WithStoreContext from './src/containers/WithStoreContext'
import WithNotificationsContext from './src/containers/WithNotificationsContext'
import WithOrdersContext from './src/containers/WithOrdersContext'

export const Root = ({ element }, customOptions) => {
	const options = {
		...defaultOptions,
		...customOptions,
	}

	const client = createClient(customOptions.wordpressUrl)

	return (
		<ApolloProvider client={client}>
			<ThemeContext.Provider value={options}>
				<WithNotificationsContext>
					<WithStoreContext>
						<WithOrdersContext>{element}</WithOrdersContext>
					</WithStoreContext>
				</WithNotificationsContext>
			</ThemeContext.Provider>
		</ApolloProvider>
	)
}
