import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import WithLocation from 'containers/WithLocation'

const LinkDuplicate = ({ location, to, children, className, ...rest }) => {
	const isDuplicate = location.pathname === to
	// prettier-ignore
	const isExternal = to.indexOf('http') !== -1
		|| to.indexOf('mailto:') !== -1
		|| to.indexOf('tel:') !== -1
		|| rest.target === '_blank'
	let classNameString = typeof className === 'object' ? className.toString() : className
	if (isDuplicate) classNameString = [className, 'link-duplicate'].filter(Boolean).join(' ')

	if (isDuplicate)
		return (
			<span className={classNameString} {...rest}>
				{children}
			</span>
		)

	const Tag = isExternal ? 'a' : Link
	const params = isExternal ? { href: to } : { to }
	return (
		<Tag {...params} className={classNameString} {...rest}>
			{children}
		</Tag>
	)
}

LinkDuplicate.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}).isRequired,
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default WithLocation(LinkDuplicate)
